




















import CommunityForm from '@/components/community/CommunityForm.vue';
import CommunityUtils, { Community } from '@/utils/CommunityUtils';
import Vue from 'vue';

export default Vue.extend({
  components: { CommunityForm },

  data(): {
    state: {
      valid: boolean;
      submit: boolean;
      errors?: any[];
    };
    item?: Partial<Community>;
  } {
    return {
      state: {
        valid: false,
        submit: false,
        errors: undefined,
      },
      item: undefined,
    };
  },

  async mounted() {
    this.item = CommunityUtils.community.generateDefault({
      deployment: this.$route.query.deployment === 'true' || false,
    });
  },

  methods: {
    async handleSubmit() {
      if (this.item) {
        try {
          this.state.errors = undefined;
          this.state.submit = true;
          (this.$refs.form as any).validate();
          this.item = await CommunityUtils.api.create(this.item);
          (this.$refs.form as any).resetValidation();
          this.$toast.push({
            text: this.$t('success.create', [this.$tc('community.label')]),
            type: 'success',
          });
          this.$router.replace({ name: 'community-list' });
        } catch (error: any) {
          this.$toast.push({
            text: this.$t('error.create', [this.$tc('community.label')]),
            type: 'error',
          });
          this.state.errors = error; // TODO: handle errors
        } finally {
          this.state.submit = false;
        }
      }
    },
  },
});
